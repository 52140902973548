@import 'gl-variable';

.nav {
    position: relative;
    width: 100%;
    background: $body-bg;
    overflow: hidden;
    height: $navbar-height;
    margin-bottom: 2px;

    &.full-width {
        .nav-content ul.nav-tabs li.nav-item {
            flex-grow: 1;
        }
    }

    .nav-content {
        overflow: scroll;
        overflow-y: hidden;
        padding-bottom: 17px;

        ul.nav-tabs {
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: intrinsic;
            /* Safari/WebKit uses a non-standard name */
            width: -moz-max-content;
            /* Firefox/Gecko */
            width: -webkit-max-content;
            /* Chrome */
            min-width: 100%;
            line-height: $navbar-height;

            li.nav-item {
                color: $gray;
                display: flex;
                //flex-grow: 1;
                text-align: center;

                a.nav-link {
                    border-bottom: 0;
                    color: $gray;
                    display: inline-block;
                    font-size: $font-size-xl;
                    font-weight: $font-weight-bold;
                    height: $navbar-height;
                    //line-height: $navbar-height;
                    padding: 0 $padding-xxl-horizontal;
                    margin-left: auto;
                    margin-right: auto;
                    //text-transform: uppercase;
                    transition: all .3s;

                    &.active {
                        color: $color-primary;
                        border-bottom: solid 4px $color-primary;
                    }

                    &.disabled {
                        color: $silver;
                        cursor: default;
                    }

                    &:hover:not(.active):not(.disabled) {
                        color: $gray-light;
                    }
                }
            }
        }
    }

    &.nav-sticky {
        position: fixed;
        top: $navbar-height;
        z-index: 98;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
        transition: top .5s ease-in-out;

        &.nav-top {
            top: 0;
        }
    }
}

.nav.nav-small {
    height: calc($navbar-height / 2);

    .nav-content ul.nav-tabs {
        line-height: calc($navbar-height / 2);

        li.nav-item a.nav-link {
            font-size: $font-size-base;
            height: calc($navbar-height / 2);
            padding: 0 15px;

            &.active {
                border-bottom: solid 3px $color-primary;
            }
        }
    }
}